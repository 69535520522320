/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Page from "../components/page"
import Blade from "../components/blade"
import querystring from "query-string"
import { useLocation } from "react-use"

const CalendarPage = ({ data, ...restProps }) => {
  const { search } = useLocation()
  const disciplines = data?.allContentfulDiscipline?.nodes
  const selectedDisciplineName = querystring.parse(search)?.discipline
  const selectedDiscipline = disciplines?.find(
    discipline => discipline.disciplineName === selectedDisciplineName
  )
  const desktopAdUrl =
    selectedDiscipline?.desktopAdUrl || data.contentfulPage.desktopAdUrl
  const mobileAdUrl =
    selectedDiscipline?.mobileAdUrl || data.contentfulPage.mobileAdUrl
  return (
    <Page data={data.contentfulPage}>
      {data.contentfulPage.blades?.map((blade, index) => (
        <Blade
          key={index}
          blade={blade}
          desktopAdUrl={desktopAdUrl}
          mobileAdUrl={mobileAdUrl}
          {...restProps}
        />
      ))}
    </Page>
  )
}

export default CalendarPage

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "calendar" }) {
      ...PageData
      ...Blade
    }
    allContentfulDiscipline {
      nodes {
        disciplineName
        mobileAdUrl
        desktopAdUrl
      }
    }
  }
`
